export const MARKET_SUBVERSION_MAP = {
    "nyc01": ["00","01","02"],
    "chi01":["01","02"],
    "chicago01":["01","02"],
    "phl01":["01","02"],
    "dc01":["01","02"],
    "la01":["01","02"],
    "bos01":["01","02"],
    "miami01":["01","02"],
    "fort_lau01":["01","02"],
    "nola01":["01","02"],
    "reno01":["01","02"],
    "sanfran01":["01","02"],
    "nyc02":["00","01"],
    "chi02":["00"],
    "chicago02":["00"],
    "phl02":["00"],
    "dc02":["00"],
    "la02":["00"],
    "bos02":["00"],
    "miami02":["00"],
    "fort_lau02":["00"],
    "nola02":["00"],
    "reno02":["00"],
    "sanfran02":["00"],
    "nyc03": ["00"],
    "chi03":["00"],
    "chicago03":["00"],
    "phl03":["00"],
    "dc03":["00"],
    "la03":["00"],
    "bos03":["00"],
    "miami03":["00"],
    "fort_lau03":["00"],
    "nola03":["00"],
    "reno03":["00"],
    "sanfran03":["00"],
    "nyc04": ["00","01"],
    "chi04":["00","01"],
    "chicago04":["00","01"],
    "phl04":["00","01"],
    "dc04":["00","01"],
    "la04":["00","01"],
    "bos04":["00","01"],
    "miami04":["00","01"],
    "fort_lau04":["00","01"],
    "nola04":["00","01"],
    "reno04":["00","01"],
    "sanfran04":["00","01"],
    "nyc05": ["00"],
    "chi05":["01"],
    "chicago05":["01"],
    "phl05":["00"],
    "dc05":["00"],
    "la05":["00"],
    "bos05":["00"],
    "miami05":["00"],
    "fort_lau05":["00"],
    "nola05":["00"],
    "reno05":["00"],
    "sanfran05":["00"],
    "nyc06": ["00","01"],
    "chi06":["00","01"],
    "chicago06":["00","01"],
    "phl06":["00","01"],
    "dc06":["00","01"],
    "la06":["00","01"],
    "bos06":["00","01"],
    "miami06":["00","01"],
    "fort_lau06":["00","01"],
    "nola06":["00","01"],
    "reno06":["00","01"],
    "sanfran06":["00","01"],
    "nyc07": ["00"],
    "chi07":["00"],
    "chicago07":["00"],
    "nyc08": ["00"],
    "chi08":["00"],
    "chicago08":["00"],
    "phl08":["00"],
    "dc08":["00"],
    "la08":["00"],
    "bos08":["00"],
    "miami08":["00"],
    "fort_lau08":["00"],
    "nola08":["00"],
    "reno08":["00"],
    "sanfran08":["00"],
    "nyc09": ["00"],
    "chi09":["00"],
    "chicago09":["00"],
    "phl09":["00"],
    "dc09":["00"],
    "la09":["00"],
    "bos09":["00"],
    "miami09":["00"],
    "fort_lau09":["00"],
    "nola09":["00"],
    "reno09":["00"],
    "sanfran09":["00"],
    "nyc10": ["00"],
    "chi10":["00"],
    "chicago10":["00"],
    "phl10":["00"],
    "dc10":["00"],
    "la10":["00"],
    "bos10":["00"],
    "miami10":["00"],
    "fort_lau10":["00"],
    "nola10":["00"],
    "reno10":["00"],
    "sanfran10":["00"],
}